var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "lg",
      "opacity": "0.6",
      "spinner-variant": "primary"
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        }), _c('b-spinner', {
          attrs: {
            "type": "grow",
            "variant": "dark"
          }
        }), _c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "w-full border-0"
  }, [_c('div', {
    staticClass: "w-1/2 pr-2.5"
  }, [_c('label', {
    staticClass: "font-normal mb-2"
  }, [_vm._v("Cover Image File")]), _c('input', {
    ref: "fileInput",
    staticClass: "border w-full rounded-lg",
    attrs: {
      "type": "file",
      "placeholder": "No file chosen"
    },
    on: {
      "change": _vm.handleFileUpload
    }
  }), _c('span', {
    staticClass: "text-gray-500 mt-2 mb-3 block"
  }, [_vm._v("Max Size 10MB")])]), _c('div', {
    staticClass: "w-full flex justify-start"
  }, [_vm._l(_vm.imagePreviews, function (preview, index) {
    return _c('div', {
      key: index,
      staticClass: "flex mt-5"
    }, [_c('div', {
      staticClass: "flex-col mr-3"
    }, [_c('div', {
      staticClass: "w-full rounded-lg"
    }, [_c('img', {
      staticClass: "shadow-sm border rounded-lg",
      attrs: {
        "src": preview.file_cover,
        "alt": preview.alt
      }
    })]), _c('div', {
      staticClass: "w-full text-center"
    }, [preview.alt === 178 ? _c('div', [_c('p', {
      staticClass: "mb-0"
    }, [_vm._v("image normal")]), _c('p', [_vm._v(_vm._s(preview.alt) + "px")])]) : _vm._e(), preview.alt === 256 ? _c('div', [_c('p', {
      staticClass: "mb-0"
    }, [_vm._v("image highres")]), _c('p', [_vm._v(_vm._s(preview.alt) + "px")])]) : _vm._e(), preview.alt === 380 ? _c('div', [_c('p', {
      staticClass: "mb-0"
    }, [_vm._v("image highres")]), _c('p', [_vm._v(_vm._s(preview.alt) + "px")])]) : _vm._e(), preview.alt === 760 ? _c('div', [_c('p', {
      staticClass: "mb-0"
    }, [_vm._v("image highres")]), _c('p', [_vm._v(_vm._s(preview.alt) + "px")])]) : _vm._e()])])]);
  }), _vm.imagePreviews.length > 0 ? _c('div', {
    staticClass: "ml-3 mt-5"
  }, [_c('button', {
    staticClass: "mr-2 bg-blue-700 text-white p-2 rounded-lg",
    on: {
      "click": function ($event) {
        return _vm.onUploadCover(_vm.imagePreviews);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-upload"
  }), _vm._v(" upload ")]), _c('button', {
    staticClass: "bg-white border p-2 px-2 rounded-lg",
    on: {
      "click": function ($event) {
        return _vm.onCancelUpload();
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-times"
  }), _vm._v(" Cancel ")])]) : _vm._e()], 2), _c('label', {
    staticClass: "font-normal mb-2"
  }, [_vm._v("Image Preview")]), _c('div', {
    staticClass: "flex justify-start",
    class: {
      'd-none': _vm.imagePreviews.length > 0
    }
  }, _vm._l(_vm.BUCKET_COVER_SCALE_VALUE, function (scale) {
    return _c('div', {
      key: scale,
      staticClass: "p-0"
    }, [_c('div', {
      staticClass: "mr-3 text-center"
    }, [_c('a', {
      attrs: {
        "href": _vm.basePathCover + _vm.removeBucket(_vm.item[scale]),
        "target": "_blank"
      }
    }, [_c('img', {
      staticClass: "img-cover w-44 shadow-sm rounded",
      attrs: {
        "src": _vm.basePathCover + _vm.removeBucket(_vm.item[scale]) + '?' + _vm.now,
        "alt": 'cover-' + scale
      }
    }), _c('span', {
      staticClass: "text-base mt-2 block text-center text-gray-500"
    }, [_vm._v(" " + _vm._s(_vm.PIXEL[scale]) + " px , " + _vm._s(_vm.DPI) + " DPI ")])])])]);
  }), 0)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }